import data from "../../assets/data/test.templates.json";

export const loadTemplates = async () => {
  return data;
};

export const fetchTemplate = async (templateID) => {
  const selectedTemplate = data.find((t) => t.template_id === templateID);
  return selectedTemplate;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loadTemplates,
  fetchTemplate,
};
