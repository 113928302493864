import React from "react";
import { NavLink } from "react-router-dom";

const MenuItem = ({ to, label }) => {
    return (        
        <NavLink exact to={to} className="nav-link">                
            {label}                    
        </NavLink>        
    );
};

export default MenuItem;