import React from "react";

// Wrappers
import PageWrapper from "./../../../components/wrappers/page-wrapper/PageWrapper"

// Components
import BlendDetailContent from "./../../../components/blend/blend-detail-content/BlendDetailContent"


const BlendDetail = () => {
  return (
    <PageWrapper>
      <BlendDetailContent />
    </PageWrapper>
  );
};

export default BlendDetail;