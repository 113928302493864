import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "./history";

// Routes
import navRoutes from "./constants/navigationRoutes";

// Components
import PackList from "./../views/pack/pack-list/PackList";
import BlendList from "./../views/blend/blend-list/BlendList";
import GrimderList from "./../views/blend/blend-list/GrimderList";
import GrimdexList from "./../views/blend/blend-list/GrimdexList";
import GarageSeriesList from "./../views/blend/blend-list/GarageSeriesList";
import BlendDetail from "./../views/blend/blend-detail/BlendDetail";

const AppRouter = () => {  
    return (
        <Router history={history}>
            <Switch>
                <Route path={navRoutes.LIST_PACKS} component={PackList} />
                <Route path={navRoutes.DETAIL_BLEND} component={BlendDetail} /> 
                <Route path={navRoutes.LIST_BLENDS} component={BlendList} />    
                <Route path={navRoutes.LIST_GRIMDER} component={GrimderList} />
                <Route path={navRoutes.LIST_GRIMDEX} component={GrimdexList} />
                <Route path={navRoutes.LIST_GARAGE_SERIES} component={GarageSeriesList} />

                <Route render={() => <Redirect to={navRoutes.LIST_PACKS} />} />
            </Switch>
        </Router>
    );
};

export default AppRouter;