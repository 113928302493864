/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoadPackList } from "./../../../store/actions/packs.action"
import Repository from "../../../services/repositories/repositoryFactory";

// Components
import Modal from "./../../common/modal/Modal"
import Loader from "../../common/loader/Loader";
import PackItem from "../pack-item/PackItem";

const PackRepository = Repository.get("pack");

const PackListContent = () => {

    const { packList } = useSelector((state) => state.pack);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(async () => {
        try {
            setLoading(true);
            const packs = await PackRepository.loadPacks();            
            dispatch(LoadPackList(packs));            
            setLoading(false);            
        } catch (e) {
            setLoading(false);
        }
    }, []);

    return (        
            <div className="row">
                <Modal />
                {loading && <Loader/>}                   
                {!loading && packList.length ? (     
                    <>                     
                        {packList.map((pack) => (                            
                            <PackItem pack={pack} key={pack.pack_id} />                            
                        ))}
                    </>
            ): null}                        
        </div>                                                                            
    );
};

export default PackListContent;