import React from "react";

const FooterLogo = ({ to, img, alt }) => {
    return (
        <div className="col text-center">
            <a className="nav-link" href={to}>
                <img src={img} alt={alt} className="img-fluid"/>
            </a>
        </div>
    );
};

export default FooterLogo;