export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const UserLogin = (payload) => {    
    return {
      type: USER_LOGIN,
      payload,
    };
};

export const UserLogout = () => {
    return {
      type: USER_LOGOUT
    };
};