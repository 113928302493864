import React from "react";

// Style
import './PageWrapper.css'
import background from "./../../../assets/background.jpeg";

// Layout components
import Header from "../../layout/header/header/Header";
import Footer from "../../layout/footer/footer/Footer";

const PageWrapper = ({ children }) => {
    return (
        <div className="d-flex flex-column wrapper" style={{ backgroundImage: `url(${background})` }}>
            <Header />
            <main role="main" className="flex-fill">
                <div className="container">
                    {children}    
                </div>            
            </main>            
            <Footer />
        </div>
    );    
};

export default PageWrapper;