/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { showModal } from "../../../store/actions/modal.action";
import Repository from "../../../services/repositories/repositoryFactory";
import RequiredBlendModal from "../required-blend-modal/RequiredBlendModal";
import RequiredGrimtoModal from "../required-grimto-modal/RequiredGrimtoModal";
import BlendBox from "../blend-box/BlendBox";
import classnames from "classnames";
import Modal from "./../../common/modal/Modal";

const BlendRepository = Repository.get("blend");
const AssetRepository = Repository.get("asset");

const BlendDetailContent = () => {
  const { ual } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { id: blendID } = useParams();
  const [currentBlend, setCurrentBlend] = useState(null);
  const [blendRequirements, setBlendRequirements] = useState([]);
  const [grimtosTotalNeeded, setGrimtosTotalNeeded] = useState(0);
  const [grimtosRequirements, setGrimtosRequirements] = useState([]);

  const [normalAssets, setNormalAssets] = useState([]);
  const [grimtosAssets, setGrimtosAssets] = useState([]);

  const [showModalBlend, setShowModalBlend] = useState(false);
  const [showModalGrimto, setShowModalGrimto] = useState(false);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(async () => {
    try {
      const selectedBlend = await BlendRepository.searchBlendbyID(
        parseInt(blendID)
      );
      setCurrentBlend(selectedBlend);
      setBlendRequirements(createInitialRequirements(selectedBlend));
      setGrimtosTotalNeeded(selectedBlend.grimtos);
    } catch (e) {}
  }, []);

  useEffect(async () => {
    if (ual?.activeUser !== null) {
      await fetchUserAssets();
    } else {
      setBlendRequirements(createInitialRequirements(currentBlend));
      setGrimtosRequirements([]);
    }
    console.log(ual?.activeUser);
  }, [ual?.activeUser, blendRequirements.length]);

  const fetchUserAssets = async () => {
    const test = {};
    for await (const req of blendRequirements) {
      const templateAssets = await loadAssets(req.template_id);
      console.log(templateAssets);
      test[req.template_id] = templateAssets;
    }

    let allAssets = [];
    for (const key in test) {
      allAssets = [...allAssets, ...test[key]];
    }

    await setNormalAssets(allAssets);
    const tempBlend = await BlendRepository.searchBlendbyID(
      parseInt(blendID)
    );
    let newAssets = null;
    if(tempBlend.schema == "grim.oil") {
      newAssets = await loadGrimtosOilAssets();
    } else {
      newAssets = await loadGrimtosAssets();
    }        
    await setGrimtosAssets(newAssets);
  };

  const loadAssets = async (templateID) => {
    try {
      const response = await AssetRepository.fetchAllAssetsFromTemplate(
        templateID,
        ual?.activeUser.accountName
      );
      return (await response.json()).data;
    } catch (e) {}
  };

  const loadGrimtosAssets = async () => {
    return await AssetRepository.fetchAllGrimtosAssets(
      ual?.activeUser.accountName,
      "grimfolkscol"
    );
  };

  const loadGrimtosOilAssets = async () => {
    return await AssetRepository.fetchAllGrimtosOilAssets(
      ual?.activeUser.accountName,
      "grimfolkscol"
    );
  };

  const createInitialRequirements = (blend) => {
    const requiredBlends = [];
    blend.mixture.forEach((templateID) => {
      const templateInfo = blend.mixture_data.find(t => parseInt(t.template_id) === templateID )
      requiredBlends.push({
        template_id: templateInfo.template_id,
        template: templateInfo,
        asset_id: null,
      });
    });
    return requiredBlends;
  };

  /*const createInitialRequirements = (blend) => {
    const requiredBlends = [];
    blend.mixture_data.forEach((t) => {
      requiredBlends.push({
        template_id: t.template_id,
        template: t,
        asset_id: null,
      });
    });
    return requiredBlends;
  };*/

  const handleClickRequiredBlend = (assetID, templateID, selected) => {
    const findCompare = selected ? assetID : null;
    const assetIdValue = selected ? null : assetID;
    const requiredBlendIndex = blendRequirements.findIndex(
      (br) => br.template_id === templateID && br.asset_id === findCompare
    );
    if (requiredBlendIndex !== -1) {
      const copyRequiredBlends = [...blendRequirements];
      copyRequiredBlends[requiredBlendIndex].asset_id = assetIdValue;
      /* copyRequiredBlends.splice(requiredBlendIndex, 1, {
        template_id: templateID,
        asset_id: assetIdValue,

      }); */
      setBlendRequirements(copyRequiredBlends);
    }
  };

  const handleClickRequiredGrimtos = (asset, selected) => {
    const copyRequiredGrimtos = [...grimtosRequirements];
    if (selected) {
      const requiredGrimtosIndex = copyRequiredGrimtos.findIndex(
        (a) => a.asset_id === asset.asset_id
      );
      if (requiredGrimtosIndex !== -1) {
        copyRequiredGrimtos.splice(requiredGrimtosIndex, 1);
        setGrimtosRequirements(copyRequiredGrimtos);
      }
    } else {
      const currentAmount = calculateSelectedGrimtosAmount();
      const adition = currentAmount + asset.value;
      // Quitamos restricción de que no puede pasarse del limite
      //if (adition <= grimtosTotalNeeded) {
      copyRequiredGrimtos.push(asset);
      setGrimtosRequirements(copyRequiredGrimtos);
      //}
    }
  };

  const calculateSelectedGrimtosAmount = () => {
    if (grimtosRequirements.length === 0) return 0;
    return grimtosRequirements.reduce(
      (accumulator, asset) => accumulator + asset.value,
      0
    );
  };

  const selectedGrimtosAmount = calculateSelectedGrimtosAmount();

  const enableBlendButton = () => {
    if (selectedGrimtosAmount < grimtosTotalNeeded) return false;
    let enable = true;
    blendRequirements.forEach((br) => {
      if (br.asset_id === null) {
        enable = false;
      }
    });
    return enable;
  };

  const enableBlend = enableBlendButton();

  /*

  {
        account: "atomicassets",
        name: "transfer",
        authorization: [
          {
            actor,
            permission,
          },
        ],
        data: {
          asset_ids: assets,
          memo: blendID,
          from: actor,
          to: "grftester111"
        },
      }
  */

  const generatePurchaseTransaction = (actor, permission, assets) => ({
    actions: [
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [
          {
            actor,
            permission,
          },
        ],
        data: {
          asset_ids: assets,
          memo: blendID,
          from: actor,
          to: "grimfolksbag"
        },
      }
    ],
  });

  const purchase = async () => {
    dispatch(showModal());
    try {
      var actor = ual.activeUser.accountName;
      var permission = ual.activeUser.requestPermission;
      const grimtosIds = grimtosRequirements.map((a) => parseInt(a.asset_id))
      const blendsIds = blendRequirements.map((br) => parseInt(br.asset_id))
      const arrayIds = [...blendsIds,...grimtosIds ]
      const transaction = generatePurchaseTransaction(actor, permission, arrayIds);          
      const result = await ual.activeUser.signTransaction(transaction, { blocksBehind: 3, expireSeconds: 30 });
      setBlendRequirements(createInitialRequirements(currentBlend));
      setGrimtosRequirements([]);            
      dispatch(showModal(result));      
      setIsFetching(true);
      setTimeout(async () => await onSuccessPurchase(), 5000);            
    } catch (error) {                  
      dispatch(showModal("ERROR", error.message));      
    }
  };

  const onSuccessPurchase = async () => {      
      await fetchUserAssets();
      setIsFetching(false);      
  };

  return (
    <div className="row justify-content-center my-3">
      <Modal />
      <RequiredBlendModal
        showModal={showModalBlend}
        userAssets={normalAssets}
        blendRequirements={blendRequirements}
        onClickTemplate={handleClickRequiredBlend}
        onCloseModal={() => setShowModalBlend(false)}
      />
      <RequiredGrimtoModal
        showModal={showModalGrimto}
        grimtosAssets={grimtosAssets}
        totalGrimtos={grimtosTotalNeeded}
        currentGrimtos={selectedGrimtosAmount}
        grimtosRequirements={grimtosRequirements}
        onClickTemplate={handleClickRequiredGrimtos}
        onCloseModal={() => setShowModalGrimto(false)}
      />
      <div className="col-10">
        <div className="card">
          <h5 className="card-header">{currentBlend?.name}</h5>
          <div className="card-body px-0">
            <div className="row ml-3">
              <div className="col-4 ">
                <img
                  src={currentBlend?.img}
                  className="pack-img mx-auto d-block"
                  alt={currentBlend?.name}
                />
              </div>
              <div className="col-4">
                <p>{currentBlend?.description}</p>
              </div>
            </div>
            <hr />
            <h6 className="ml-4 mb-4">BLEND RESULT</h6>
            <div className="row ml-3">
              {currentBlend?.target_data.map((t, index) => (
                <div key={index} className="col-2">
                  <BlendBox
                    img={t.immutable_data.img}
                    templateID={t.template_id}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="card-footer">
            <button
              disabled={!enableBlend}
              className={classnames(
                "btn",
                { "btn-primary": enableBlend },
                { "btn-secondary": !enableBlend }
              )}
              type="button"
              onClick={() => purchase()}
            >
              BLEND
            </button>
          </div>
        </div>

        {/* REQUIRED BLENDS */}
        {blendRequirements.length > 0 && (
          <div className="card mt-4">
            <div className="card-header">
              <div className="row d-flex justify-content-between align-items-center">
                <h5>REQUIRED BLENDS</h5>
                <button
                  disabled={ual.activeUser === null || isFetching}
                  className={classnames(
                    "btn",
                    { "btn-primary": ual.activeUser !== null && !isFetching },
                    { "btn-secondary": ual.activeUser === null || isFetching }
                  )}
                  type="button"
                  onClick={() => setShowModalBlend(true)}
                >
                  ADD
                </button>
              </div>
            </div>
            <div className="card-body px-0">
              <div className="row ml-3">
                {blendRequirements.map((t, index) => (
                  <div key={index} className="col-2">
                    <BlendBox
                      templateID={t.template_id}
                      img={t.template.immutable_data.img}
                      selected={t.asset_id !== null}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* REQUIRED GRIMTOS */}
        {grimtosTotalNeeded > 0 && (
          <div className="card mt-4">
            <div className="card-header">
              <div className="row d-flex justify-content-between align-items-center">
                <h5>REQUIRED {currentBlend.schema == 'grim.oil' ? 'GAS LITER' : 'GRIMTOS'}</h5>
                <button
                  disabled={ual.activeUser === null || isFetching}
                  className={classnames(
                    "btn",
                    { "btn-primary": ual.activeUser !== null && !isFetching},
                    { "btn-secondary": ual.activeUser === null || isFetching}
                  )}
                  type="button"
                  onClick={() => setShowModalGrimto(true)}
                >
                  ADD
                </button>
              </div>
            </div>
            <div className="card-body px-0">
              <div className="row ml-3">
                Total: {selectedGrimtosAmount} / {grimtosTotalNeeded}{" "}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlendDetailContent;
