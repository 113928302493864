import { SHOW_MODAL, HIDE_MODAL } from "../actions/modal.action";

const initialState = {
  show: false,
  result: null,
  message: null
};
const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        show: true,
        result: action.payload.result,
        message: action.payload.message,
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default ModalReducer;