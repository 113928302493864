import React from "react";

// Router
import AppRouter from "./routes/AppRouter";

// Redux store
import { Provider } from "react-redux";
import store from "./store/store";

const App = () => {
    return (
        <Provider store={store}>
            <AppRouter />
        </Provider>
    );
}

export default App;
