import React from "react";

// Wrappers
import PageWrapper from "./../../../components/wrappers/page-wrapper/PageWrapper"

// Components
import BlendListContent from "./../../../components/blend/blend-list-content/BlendListContent"

import blendsData from "../../../assets/data/grimdex.json";

const BlendList = () => {
    return (
        <PageWrapper>
            <BlendListContent blendList={blendsData}/>
        </PageWrapper>
    );
};

export default BlendList;