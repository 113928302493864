import React, { useState } from "react";
import Countdown from "react-countdown";

// Styles
import "./PackItem.css";

// Components
import PackBuyButton from "./../pack-buy-button/PackBuyButton";
import NumericInput from "react-numeric-input";

const renderer = (
  { days, hours, minutes, seconds, completed },
  pack,
  quantity
) => {
  if (completed) {
    return <PackBuyButton pack={pack} quantity={quantity} />;
  } else {
    return (
      <a href="#a" className="btn btn-primary btn-lg disabled float-right">
        <span>
          {days}d, {hours}h:{minutes}m:{seconds}s
        </span>
      </a>
    );
  }
};

const PackItem = ({ pack }) => {
  const [quantity, setQuantity] = useState("1");
  const [price, setPrice] = useState(pack.price);
  const { pack_id, name, description, unlock_time, img, collection_name } =
    pack;

  let limit_date = new Date(parseInt(unlock_time * 1000));

  const handleChange = (e) => {
    setQuantity(e);
    setPrice(e * parseFloat(pack.price));
  };

  return (
    <div className="col-sm-6 pt-4">
      <div className="card mb-4" id={"pack-" + pack_id}>
        <div className="card-header">{collection_name}</div>
        <div className="card-body">
          <div className="row pb-4">
            <div className="col-sm-6">
              <h5 className="card-title d-block d-sm-none">{name}</h5>
              <img src={img} className="pack-img mx-auto d-block" alt={name} />
            </div>
            <div className="col-sm-6">
              <h5 className="card-title d-none d-sm-block">{name}</h5>
              <p
                className="card-text pt-4"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-6">
              <span>
                <b>Price:</b> {price} WAX
              </span>
              <br />
              <br />
              <NumericInput
                className="form-control"
                min={1}
                max={100}
                value={quantity}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-6">
              <Countdown
                date={limit_date}
                renderer={(e) => renderer(e, pack, quantity)}
                zeroPadTime={4}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackItem;
