import React from "react";
import { Link } from "react-router-dom";

// Routes
import navRoutes from "../../../../routes/constants/navigationRoutes";

// Assets
import logo from '../../../../assets/header/grimfolks-brand.png'

const HeaderBrand = () => {
    return (
        <Link className="navbar-brand" to={navRoutes.HOME}>            
            <img src={logo} width="30" height="30" alt="Grimfolks" />
        </Link>        
    );
};

export default HeaderBrand;