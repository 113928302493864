import React from "react";
import classnames from "classnames";
import style from "./RequiredGrimtoModal.module.css";
import BlendBox from "../blend-box/BlendBox";

const RequiredBlendModal = ({
  showModal,
  totalGrimtos,
  currentGrimtos,
  onCloseModal,
  grimtosAssets = [],
  grimtosRequirements = [],
  onClickTemplate,
}) => {
  const selectedAssets = grimtosRequirements.map((a) => a.asset_id);

  return (
    <div className={classnames("modal", style.modal, { "d-block": showModal })}>
      <div
        className={classnames(
          "modal-dialog modal-dialog-scrollable align-self-center",
          style.modal_width
        )}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="col-10">
              <h5 className="modal-title text-uppercase">REQUIRED GRIMTOS</h5>
            </div>
            <div className="col-2">
              Total: {currentGrimtos} / {totalGrimtos}{" "}
            </div>
          </div>
          <div className="modal-body">
            <div className="row ml-3">
              {grimtosAssets.map((a, index) => (
                <div key={index} className="col-2 my-1">
                  <BlendBox
                    clickable
                    showDetails
                    img={a.data.img}
                    templateID={a.template.template_id}
                    name={a.name}
                    mint_number={a.template_mint}
                    onClick={() =>
                      onClickTemplate(a, selectedAssets.includes(a.asset_id))
                    }
                    selected={selectedAssets.includes(a.asset_id)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequiredBlendModal;
