import React from "react";
import classnames from "classnames";
import style from "./RequitedBlendModal.module.css";
import BlendBox from "../blend-box/BlendBox";

const RequiredBlendModal = ({
  showModal,
  onCloseModal,
  blendRequirements = [],
  onClickTemplate,
  userAssets,
}) => {
  const pickedAssets = blendRequirements.map((t) => t.asset_id);

  return (
    <div className={classnames("modal", style.modal, { "d-block": showModal })}>
      <div
        className={classnames(
          "modal-dialog modal-dialog-scrollable align-self-center",
          style.modal_width
        )}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-uppercase">REQUIRED BLENDS</h5>
          </div>
          <div className="modal-body">
            <div className="row ml-3">
              {userAssets.map((a, index) => (
                <div key={index} className="col-2 my-1">
                  <BlendBox
                    clickable
                    showDetails
                    img={a.data.img}
                    templateID={a.template.template_id}
                    name={a.name}
                    mint_number={a.template_mint}
                    onClick={() =>
                      onClickTemplate(
                        a.asset_id,
                        a.template.template_id,
                        pickedAssets.includes(a.asset_id)
                      )
                    }
                    selected={pickedAssets.includes(a.asset_id)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequiredBlendModal;
