const navigationRouter = {
    // External routes
    HOME: "https://www.grimfolks.com/",
    FAQ: "https://www.grimfolks.com/faq/",
    MEET_THE_ARTIST: "https://www.alexgallego.com/",
    DKRENDER: "https://3dkrender.com/",
    UPLIFT: "https://theuplift.world/",
    BEETRICKTECH: "https://beetricktech.com/",
    OPEN: "https://neftyblocks.com/c/grimfolkscol/packs",

    // Internal routes
    // PACKS
    LIST_PACKS: "/packs",
    // BLENDS
    LIST_BLENDS: "/blends",
    LIST_GRIMDER: "/grimder",
    LIST_GRIMDEX: "/grimdex",
    LIST_GARAGE_SERIES: "/garage-series",
    
    DETAIL_BLEND: "/blends/:id"
};

export default navigationRouter;