import React from "react";

// Routes
import navRoutes from "../../../../routes/constants/navigationRoutes";

// Components
import HeaderLogin from "../header-login/HeaderLogin";
import MenuItem from "./../../menu/menu-item/MenuItem"
import UALWrapper from "./../../../wrappers/ual-wrapper/UALWrapper"

const HeaderMenu = () => {
    return (
        <>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">                
                <ul className="navbar-nav mx-auto">
                    <li className="nav-item">
                        <a className="nav-link" href={navRoutes.HOME}>
                            Home
                        </a>                        
                    </li>
                    <li className="nav-item">
                        <MenuItem to={navRoutes.LIST_PACKS} label="Packs"/>
                    </li>                    
                    <li className="nav-item">
                        <a className="nav-link" href={navRoutes.OPEN}>
                            Open
                        </a>                          
                    </li>
                    <li className="nav-item">
                        <MenuItem to={navRoutes.LIST_BLENDS} label="Blends"/>
                    </li>
                    <li className="nav-item">
                        <MenuItem to={navRoutes.LIST_GRIMDER} label="Lottery Grimder"/>
                    </li> 
                    <li className="nav-item">
                        <MenuItem to={navRoutes.LIST_GRIMDEX} label="GrimDeX"/>
                    </li>
                    <li className="nav-item">
                        <MenuItem to={navRoutes.LIST_GARAGE_SERIES} label="Garage Series"/>
                    </li>                  
                    <li className="nav-item">
                        <a className="nav-link" href={navRoutes.MEET_THE_ARTIST}>
                            Meet the artist
                        </a>                        
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href={navRoutes.FAQ}>
                            FAQ
                        </a>                        
                    </li>                  
                </ul>
                <UALWrapper children={HeaderLogin}/>                  
            </div>
        </>           
    );
};

export default HeaderMenu;