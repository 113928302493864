/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import classnames from "classnames";
import style from "./BlendBox.module.css";

const BASE_IMG_URL = "https://ipfs.io/ipfs/";

const BlendBox = ({
  templateID,
  selected = false,
  clickable = false,
  onClick = null,
  name = null,
  showDetails = false,
  mint_number = null,
  img = null,
}) => {
  return (
    <div
      onClick={onClick}
      className={classnames(
        "w-100",
        style.box,
        { [style.selected]: selected },
        { [style.clickable]: clickable }
      )}
    >
      <span>ID: {templateID}</span>

      <div className="w-75 mb-3">
        <img
          src={img ? BASE_IMG_URL + img : null}
          className="pack-img mx-auto d-block"
          alt={name}
        />
      </div>
      {showDetails && <div>Name: {name}</div>}
      {showDetails && <div>Mint number: {mint_number}</div>}
    </div>
  );
};

export default BlendBox;
