import data from "../../assets/data/all.blends.json";
import blends from "../../assets/data/blends.json";
import grimdex from "../../assets/data/grimdex.json";
import grimder from "../../assets/data/grimder.json";
import { JsonRpc } from "eosjs";

// const RESOURCE = "https://test.wax.api.atomicassets.io/atomicassets/v1/templates";
const RESOURCE = "https://wax.api.atomicassets.io/atomicassets/v1/templates";

// const RPC = new JsonRpc("https://testnet.waxsweden.org");
const RPC = new JsonRpc("https://api.waxsweden.org");

export const loadBlends = async () => {
  return blends;
};

export const loadGrimdex = async () => {
  return grimdex;
};

export const loadGrimder = async () => {
  return grimder;
};

export const fetchBlend = async (blendID) => {
  const selectedBlend = data.find((b) => b.blend_id === blendID);
  return selectedBlend;
};

export const searchBlendbyID = async (blendID) => {

  /*
  {
        json: true,
        code: "grftester111",
        limit: "100",
        scope: "grftester111",
        table: "blenders",
        lower_bound: blendID,
        upper_bound: blendID,
      }
  */

  try {
    const blend = (
      await RPC.get_table_rows({
        json: true,
        code: "grimfolksbag",
        limit: "100",
        scope: "grimfolksbag",
        table: "blenders",
        lower_bound: blendID,
        upper_bound: blendID,
      })
    ).rows[0];

    const ids = [...blend.mixture, ...blend.target]
      .toString()
      .replace(",", "%2C");
    const url = RESOURCE + `?ids=${ids}&page=1&limit=100`;
    const method = "POST";
    const headers = {
      Accept: "application/json",
    };
    const response = await fetch(url, { method, headers });
    const templates = (await response.json()).data;

    const mixture_data = templates.filter((t) =>
      blend.mixture.includes(parseInt(t.template_id))
    );
    const target_data = templates.filter((t) =>
      blend.target.includes(parseInt(t.template_id))
    );

    const testBlendInfo = await fetchBlend(parseInt(blend.id))
    
    return {
      ...testBlendInfo,
      ...blend,
      mixture_data,
      target_data,
    };
  } catch (error) {
    return null;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loadBlends,
  loadGrimder,
  loadGrimdex,
  fetchBlend,
  searchBlendbyID,
};
