import React from "react";

// Styles
import './FooterMenu.css'

// Routes
import navRoutes from "../../../../routes/constants/navigationRoutes";

// Components
import MenuItem from './../../menu/menu-item/MenuItem'

const FooterMenu = () => {
    return (
        <ul className="list-inline">
            <li className="list-inline-item">
                <a className="nav-link text-white" href={navRoutes.HOME}>
                    Home
                </a>                        
            </li>
            <li className="list-inline-item custom-link">
                <MenuItem to={navRoutes.LIST_PACKS} label="Packs"/>
            </li>                    
            <li className="list-inline-item">
                <a className="nav-link text-white" href={navRoutes.OPEN}>
                    Open
                </a>
            </li>   
            <li className="list-inline-item custom-link">
                <MenuItem to={navRoutes.LIST_BLENDS} label="Blends"/>
            </li>
            <li className="list-inline-item custom-link">
                <MenuItem to={navRoutes.LIST_GRIMDER} label="Lottery Grimder"/>
            </li> 
            <li className="list-inline-item custom-link">
                <MenuItem to={navRoutes.LIST_GRIMDEX} label="GrimDeX"/>
            </li>
            <li className="list-inline-item custom-link">
                <MenuItem to={navRoutes.LIST_GARAGE_SERIES} label="Garage Series"/>
            </li>               
            <li className="list-inline-item">
                <a className="nav-link text-white" href={navRoutes.MEET_THE_ARTIST}>
                    Meet the artist
                </a>                        
            </li>
            <li className="list-inline-item">
                <a className="nav-link text-white" href={navRoutes.FAQ}>
                    FAQ
                </a>                        
            </li>                  
        </ul>
    );
};

export default FooterMenu;