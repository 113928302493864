import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideModal } from "../../../store/actions/modal.action";
import classnames from "classnames";
import "./Modal.css";
import Loader from "./../loader/Loader";

const Modal = () => {
  const dispatch = useDispatch();
  const { show, result, message } = useSelector((state) => state.modal);

  const closeModal = () => {
    dispatch(hideModal());
  };

  return (
    <div className={classnames("modal", { "d-block": show })}>
      <div className="modal-dialog align-self-center">
        <div className="modal-content">
          <div
            className={classnames("modal-header", {
              "bg-light": result == null,
              "bg-danger": result === "ERROR",
              "bg-success": result !== "ERROR" && result != null,
            })}
          >
            <h5
              className={classnames("modal-title text-uppercase", {
                "text-white": result != null,
              })}
            >
              {result == null
                ? "Transaction in progress..."
                : result === "ERROR"
                ? "Transaction error"
                : "Transaction completed"}
            </h5>
          </div>
          <div className="modal-body">
            {result == null ? (
              <Loader />
            ) : result === "ERROR" ? (
              <p>
                Transaction Failed!
                <br />
                {message? message : "Unable to sign transaction"}
              </p>
            ) : (
              <p>
                Transaction Completed!
                <br />
                Check the transaction at:{" "}
                <a
                  href={
                    "https://wax.bloks.io/transaction/" +
                    result.transactionId
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Tx ID
                </a>
              </p>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
