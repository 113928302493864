import data from "../../assets/data/test.assets.json";
import TemplateRespository from "./template.repository";
import { JsonRpc } from "eosjs";

// const RPC = new JsonRpc("https://testnet.waxsweden.org");
const RPC = new JsonRpc("https://api.waxsweden.org");

// const RESOURCE = "https://test.wax.api.atomicassets.io/atomicassets/v1/assets";
const RESOURCE = "https://wax.api.atomicassets.io/atomicassets/v1/assets";

const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const loadUserAssets = async () => {
  const assets = [];
  data.forEach(async (a) => {
    const template = await TemplateRespository.fetchTemplate(a.template_id);
    assets.push({
      ...a,
      template,
    });
  });
  return assets;
};

export const fetchAssetsTemplate = async (templateID) => {
  const assets = data.filter((a) => a.template_id === templateID);
  return assets;
};

export const fetchAllAssetsFromTemplate = async (template_id, owner) => {
  const method = "POST";
  const body = JSON.stringify({
    owner,
    template_id,
  });

  return await fetch(RESOURCE, { method, headers: HEADERS, body });
};

export const fetchAllGrimtosAssets = async (owner, collection_name) => {
  try {
    const method = "POST";
    const body = JSON.stringify({
      owner,
      collection_name,
      schema_name: "banknotes",
    });

    const grimtosAssets = (
      await (await fetch(RESOURCE, { method, headers: HEADERS, body })).json()
    ).data;

    /*
    {
        json: true,
        code: "grftester111",
        limit: "100",
        scope: "grftester111",
        table: "grimtos",
      }
    */

    const grimtosRef = (
      await RPC.get_table_rows({
        json: true,
        code: "grimfolksbag",
        limit: "100",
        scope: "grimfolksbag",
        table: "grimtos",
      })
    ).rows;

    grimtosAssets.forEach((a) => {
      const grimto = grimtosRef.find(
        (g) => g.template_id === parseInt(a.template.template_id)
      );
      a.value = grimto?.grimtos;
    });

    return grimtosAssets;
  } catch (error) {
    return [];
  }
};

export const fetchAllGrimtosOilAssets = async (owner, collection_name) => {
  try {
    const method = "POST";
    const body = JSON.stringify({
      owner,
      collection_name,
      schema_name: "grim.oil",
    });

    const grimtosAssets = (
      await (await fetch(RESOURCE, { method, headers: HEADERS, body })).json()
    ).data;

    /*
    {
        json: true,
        code: "grftester111",
        limit: "100",
        scope: "grftester111",
        table: "grimtos",
      }
    */

    const grimtosRef = (
      await RPC.get_table_rows({
        json: true,
        code: "grimfolksbag",
        limit: "100",
        scope: "grimfolksbag",
        table: "grimtos",
      })
    ).rows;

    grimtosAssets.forEach((a) => {
      const grimto = grimtosRef.find(
        (g) => g.template_id === parseInt(a.template.template_id)
      );
      a.value = grimto?.grimtos;
    });

    return grimtosAssets;
  } catch (error) {
    return [];
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loadUserAssets,
  fetchAssetsTemplate,
  fetchAllAssetsFromTemplate,
  fetchAllGrimtosAssets,
  fetchAllGrimtosOilAssets,
};
