import React from "react";

const BeforeLogin = ({ual}) => {

    return (
        <button onClick={ual.showModal} className="btn btn-outline-primary my-2 my-sm-0" type="button">
            Login
        </button>
    );
};

export default BeforeLogin;