/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UserLogin } from "./../../../../store/actions/user.action";

// Components
import BeforeLogin from "./BeforeLogin";
import AfterLogin from "./AfterLogin";

const HeaderLogin = ({ ual }) => {
  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(UserLogin(ual));
  }, [ual]);

  return ual.activeUser ? <AfterLogin ual={ual} /> : <BeforeLogin ual={ual} />;
};

export default HeaderLogin;
