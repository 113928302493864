import React from "react";

// Routes
import navRoutes from "../../../../routes/constants/navigationRoutes";

// Styles
import './Footer.css'

// Assets
import dkrender from '../../../../assets/footer/3dkrender-footer.png';
import grimfolks from '../../../../assets/footer/grimfolks-footer.png';
import uplift from '../../../../assets/footer/uplift-footer.png';
import beetricktech from '../../../../assets/footer/beetricktech-footer.png';

// Components
import FooterLogo from './../footer-logo/FooterLogo'
import FooterMenu from "./../footer-menu/FooterMenu";

const Footer = () => {
    return (
        <footer className="footer mt-auto py-3">            
            <div className="container">
                <div className="row">
                    <FooterLogo to={navRoutes.DKRENDER} img={dkrender} alt='3dfkrender'/>
                    <FooterLogo to={navRoutes.HOME} img={grimfolks} alt='grimfolks'/>
                    <FooterLogo to={navRoutes.UPLIFT} img={uplift} alt='theuplift'/>
                    <FooterLogo to={navRoutes.BEETRICKTECH} img={beetricktech} alt='beetricktech'/>
                </div>                
            </div>
            <div className="container text-center">
                <FooterMenu />
            </div>
        </footer>
    );
};

export default Footer;