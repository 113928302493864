import React from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { showModal } from "../../../store/actions/modal.action";

// Components
//import BeforeLogin from './../../layout/header/header-login/BeforeLogin'

const PackBuyButton = ({ pack, quantity }) => {
  const ual = useSelector((state) => state.user.ual);
  const dispatch = useDispatch();
  const generatePurchaseTransaction = (actor, permission, pack) => ({
    actions: [
      {
        account: "atomicdropsx",
        name: "assertdrop",
        authorization: [
          {
            actor,
            permission,
          },
        ],
        data: {
          drop_id: pack.pack_id,
          assets_to_mint_to_assert: [
            {
              template_id: pack.pack_template_id,
              tokens_to_back: [],
            },
          ],
          listing_price_to_assert: parseFloat(pack.price).toFixed(8) + " WAX",
          settlement_symbol_to_assert: "8,WAX",
        },
      },
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [
          {
            actor,
            permission,
          },
        ],
        data: {
          from: actor,
          memo: "deposit",
          quantity:
            String(parseFloat(quantity * parseFloat(pack.price)).toFixed(8)) +
            " WAX",
          to: "atomicdropsx",
        },
      },
      {
        account: "atomicdropsx",
        name: "claimdrop",
        authorization: [
          {
            actor,
            permission,
          },
        ],
        data: {
          claim_amount: quantity,
          claimer: actor,
          country: "",
          drop_id: pack.pack_id,
          intended_delphi_median: 0,
          referrer: "grimfolks.com",
        },
      },
    ],
  });

  const purchase = async (pack) => {
    dispatch(showModal());
    try {
      var actor = ual.activeUser.accountName;
      var permission = ual.activeUser.requestPermission;
      const transaction = generatePurchaseTransaction(actor, permission, pack);
      const result = await ual.activeUser.signTransaction(transaction, {});
      dispatch(showModal(result));
    } catch (error) {
      dispatch(showModal("ERROR"));
    }
  };

  const buttonLogin = (
    <button
      onClick={ual.showModal}
      className="btn btn-primary btn-lg float-right"
      type="button"
    >
      Login
    </button>
  );
  const buttonBuy = (
    <button
      onClick={() => purchase(pack)}
      className="btn btn-primary btn-lg float-right"
      type="button"
    >
      Buy
    </button>
  );

  return ual.activeUser ? buttonBuy : buttonLogin;
};

export default PackBuyButton;
