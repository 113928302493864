import React from "react";

// Layout Header Components
import HeaderBrand from "../header-brand/HeaderBrand";
import HeaderMenu from "../header-menu/HeaderMenu";

// Styles
import './Header.css'

const Header = () => {
    return (        
        <header>
            <nav className="navbar navbar-expand-md sticky-top navbar-dark header-custom-bg">                        
                <HeaderBrand />
                <HeaderMenu />                     
            </nav>  
        </header>      
    );
};

export default Header;