import React from "react";

// Wrappers
import PageWrapper from "./../../../components/wrappers/page-wrapper/PageWrapper"

// Components
import PackListContent from "./../../../components/pack/pack-list-content/PackListContent"

const PackList = () => {
    return (
        <PageWrapper>
            <PackListContent />
        </PageWrapper>
    );
};

export default PackList;