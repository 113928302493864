import PackRespository from "./pack.repository";
import BlendRespository from "./blend.repository";
import TemplateRepository from "./template.repository";
import AssetRepository from "./assets.repository";

const repositories = {
  pack: PackRespository,
  blend: BlendRespository,
  template: TemplateRepository,
  asset: AssetRepository,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: (name) => repositories[name],
};
