/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoadBlendList } from "./../../../store/actions/blends.action"
import Repository from "../../../services/repositories/repositoryFactory";

// Components
import Loader from "../../common/loader/Loader";
import BlendItem from "../blend-item/BlendItem";

const BlendRepository = Repository.get("blend");

const BlendListContent = ({blendList}) => {

    /*const { blendList } = useSelector((state) => state.blend);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(async () => {
        try {
            setLoading(true);
            const blends = await BlendRepository.loadBlends();            
            dispatch(LoadBlendList(blends));            
            setLoading(false);            
        } catch (e) {
            setLoading(false);
        }
    }, []);*/

    return (        
            <div className="row">                                                  
                {blendList.length ? (     
                    <>                     
                        {blendList.map((blend) => (                            
                            <BlendItem blend={blend} key={blend.blend_id} />                            
                        ))}
                    </>
            ): null}                        
        </div>                                                                            
    );
};

export default BlendListContent;