import {
    LOAD_BLEND_LIST
} from "../actions/blends.action";

const initialState = {
    is_loaded: false,
    blendList: [],
    currentBlend: null,
};

const BlendReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_BLEND_LIST:
            return {
                ...state,
                is_loaded: true,
                blendList: action.payload,
                currentBlend: null
            };

        default:
            return state;
    }
};

export default BlendReducer;
