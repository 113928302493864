import { combineReducers } from "redux";
import PackReducer from "./packs.reducer";
import UserReducer from "./user.reducer";
import ModalReducer from "./modal.reducer";
import BlendReducer from "./blends.reducer";

const rootReducer = combineReducers({    
    pack: PackReducer,
    user: UserReducer,
    modal: ModalReducer,
    blend: BlendReducer
});

export default rootReducer;