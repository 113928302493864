/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

const AfterLogin = ({ ual }) => {
  const [accountBalance, setAccountBalance] = useState("-");

  useEffect(async () => {
    try {
      var updatedBalance = await ual.activeUser.rpc.get_currency_balance(
        "eosio.token",
        ual.activeUser.accountName,
        "WAX"
      );
      setAccountBalance(roundBalance(updatedBalance));
    } catch (e) {
      setAccountBalance("Error");
    }
  }, []);

  return (
    <div className="dropdown">
      <button
        className="btn btn-light dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {ual.activeUser.accountName} &nbsp;&nbsp;
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
      >
        <span className="dropdown-item-text small">{accountBalance} WAX</span>
        <div className="dropdown-divider"></div>
        <button className="dropdown-item" type="button" onClick={ual.logout}>
          Logout
        </button>
      </div>
    </div>
  );

  function roundBalance(balance) {
    var array = String(balance).split(" ");
    if (array.length > 1) {
      return Number(parseFloat(array[0]).toFixed(3));
    } else {
      return "Error";
    }
  }
};

export default AfterLogin;
