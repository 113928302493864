import {
    LOAD_PACK_LIST
} from "../actions/packs.action";

const initialState = {
    is_loaded: false,
    packList: [],
    currentPack: null,
};

const PackReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PACK_LIST:
            return {
                ...state,
                is_loaded: true,
                packList: action.payload,
                currentPack: null
            };

        default:
            return state;
    }
};

export default PackReducer;
