import React from "react";

// UAL
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";

const myChain = {
  /*chainId: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12",
  name: "WAX (Testnet)",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "waxtestnet.greymass.com",
      port: 443,
    },
  ],*/
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    name: 'WAX',
    rpcEndpoints: [{
        protocol: 'https',
        host: 'chain.wax.io',
        port: 443,
    }
  ]
};

const anchor = new Anchor([myChain], {
  appName: "ual-anchor-demo",
  service: "https://cb.anchor.link",
});

const wax = new Wax([myChain], {
  appName: "ual-wax-demo",
});

const UALWrapper = ({ children }) => {
  // children is the component we want to wrap the ual around
  const MyUALConsumer = withUAL(children);

  return (
    <UALProvider
      chains={[myChain]}
      authenticators={[anchor, wax]}
      appName={"grimfolks"}
      key={myChain.chainId}
    >
      <MyUALConsumer />
    </UALProvider>
  );
};

export default UALWrapper;
