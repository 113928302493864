import React from "react";
import { NavLink } from "react-router-dom";

// Routes
import navRoutes from "./../../../routes/constants/navigationRoutes";

// Styles
import "./BlendItem.css";

const BlendItem = ({ blend }) => {
  return (
    <div className="col-sm-6 pt-4">
      <div className="card mb-4">
        <div className="card-body">
          <div className="row pb-4">
            <div className="col-sm-6">
              <h5 className="card-title d-block d-sm-none">{blend.name}</h5>
              <img
                src={blend.img}
                className="pack-img mx-auto d-block"
                alt={blend.name}
              />
            </div>
            <div className="col-sm-6">
              <h5 className="card-title d-none d-sm-block">{blend.name}</h5>
              <p
                className="card-text pt-4"
                dangerouslySetInnerHTML={{ __html: blend.description }}
              />
            </div>
          </div>
        </div>
        <div className="card-footer text-center">
          <NavLink
            exact
            to={navRoutes.DETAIL_BLEND.replace(":id", blend.blend_id)}
            className="btn btn-primary btn-lg"
          >
            View
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default BlendItem;
