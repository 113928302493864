import {
    USER_LOGIN, 
    USER_LOGOUT
} from "../actions/user.action";

const initialState = {    
    ual: null,
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,                
                ual: action.payload                
            };
        
        case USER_LOGOUT:
            return initialState;

        default:
            return state;
    }
};

export default UserReducer;
